/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, LazyExoticComponent, lazy } from 'react';
import MenuItem, { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { Navigate } from 'react-router-dom';
import SubscriptionConditional from 'features/subscriptions/SubscriptionConditional';
import { SubscriptionPlan } from 'models/Subscription';
import PromoPartners from 'features/promo-partners/PromoPartners';
import Feature from 'components/feature/Feature';

const lazyWithChunkRetry = (
  importFn: () => Promise<any>
): LazyExoticComponent<ComponentType<React.PropsWithChildren<any>>> =>
  lazy(async () => {
    const pageAlreadyRefresh = JSON.parse(
      window.sessionStorage.getItem('page-force-refreshed') || 'false'
    );

    try {
      const component = await importFn();
      window.sessionStorage.setItem('page-force-refreshed', 'false');
      return component;
    } catch (error) {
      if (!Boolean(pageAlreadyRefresh) || pageAlreadyRefresh === 'false') {
        window.sessionStorage.setItem('page-force-refreshed', 'true');
        return window.location.reload();
      }
      // Page has already reloaded once
      return Promise.reject();
    }
  });

// START ROUTES LAZY LOADING //
const HomePage = lazyWithChunkRetry(() => import('pages/landing/HomePage'));
const ReleaseListWithSearch = lazyWithChunkRetry(() =>
  import('pages/release-list/ReleaseListWithSearch')
);
const TracksPage = lazyWithChunkRetry(() =>
  import('pages/tracks/TracksWrapper')
);
const Track = lazyWithChunkRetry(() => import('pages/tracks/Track'));
const ReleaseEditBaseData = lazyWithChunkRetry(() =>
  import('pages/release-edit/base-data/BaseData')
);
const ReleaseEditArtworkPage = lazyWithChunkRetry(() =>
  import('pages/release-edit/artwork/ArtworkPage')
);
const ReleaseEditTracklistPage = lazyWithChunkRetry(() =>
  import('pages/release-edit/tracklist/TracklistPage')
);
const ReleaseEditDistributionPage = lazyWithChunkRetry(() =>
  import('pages/release-edit/distribution/DistributionPage')
);
const ReleaseEditReviewPage = lazyWithChunkRetry(() =>
  import('pages/release-edit/review/ReviewPage')
);
const ReleaseViewReleaseDetails = lazyWithChunkRetry(() =>
  import('pages/release-details/ReleaseDetails')
);
const StatsPage = lazyWithChunkRetry(() => import('pages/stats/Stats'));
const BalancePage = lazyWithChunkRetry(() => import('pages/balance/Balance'));
const LoginPage = lazyWithChunkRetry(() => import('pages/login/Login'));
const LoginCiamRedirect = lazyWithChunkRetry(() =>
  import('pages/login/LoginCiamRedirect')
);
const RegisterPage = lazyWithChunkRetry(() =>
  import('pages/register/Register')
);
const EmailVerificationPage = lazyWithChunkRetry(() =>
  import('pages/email-verification/EmailVerification')
);
const CrossDomainCredentialsIframe = lazyWithChunkRetry(() =>
  import('pages/login/CrossDomainCredentialsIframe')
);
const AccountPage = lazyWithChunkRetry(() => import('pages/account/Account'));
const BioPage = lazyWithChunkRetry(() => import('features/bio-page/BioPage'));
const BioPageEdit = lazyWithChunkRetry(() =>
  import('features/bio-page/BioPageEdit')
);
const BioPageAnalytics = lazyWithChunkRetry(() =>
  import('features/bio-page/BioPageAnalytics')
);
const ReferralRedirect = lazyWithChunkRetry(() =>
  import('features/referral-program/ReferralRedirect')
);
const RoyaltiesPage = lazyWithChunkRetry(() =>
  import('features/royalties/Royalties')
);
// END ROUTES LAZY LOADING //

export interface RouteProps {
  pageKey: string;
  value: string;
  page: ComponentType<React.PropsWithChildren<unknown>>;
  isInGlobalNav: boolean;
  isPublic: boolean;
  isParentRoute: boolean;
  customNavItem?: React.ElementType<MenuItemProps>;
  isNewFeature?: boolean;
}

export const paths = {
  home: '/',
  releases: '/releases',
  releaseEdit: '/release/:id/edit/release',
  releaseEditArtwork: '/release/:id/edit/artwork',
  releaseEditTracklist: '/release/:id/edit/tracklist',
  releaseEditDistribution: '/release/:id/edit/distribution',
  releaseEditReview: '/release/:id/edit/review',
  releaseView: '/release/:id',
  tracks: '/tracks',
  trackAudio: '/tracks/:id/audio',
  trackNotes: '/tracks/:id/notes',
  trackMetadata: '/tracks/:id/metadata',
  trackLyrics: '/tracks/:id/lyrics',
  trackTab: '/tracks/:id',
  trackWork: '/tracks/:id/work',
  trackMonitoring: '/tracks/:id/monitoring',
  login: '/login',
  loginRedirect: '/signin-redirect',
  signOutRedirect: '/signout-redirect',
  register: '/register',
  emailVerification: '/email-verification',
  account: '/account',
  profileDetails: '/account/details',
  royaltyPayouts: '/account/royalty-payouts',
  subscription: '/account/subscription',
  referralProgram: '/account/referral-program',
  stats: '/stats',
  balance: '/balance',
  bioPages: '/bio-pages',
  bioPageEditTabs: '/bio-page/:id/edit/:tab',
  bioPageAnalytics: '/bio-page/:id/edit/analytics',
  bioPageEditContent: '/bio-page/:id/edit/content',
  bioPageEdit: '/bio-page/:id/edit',
  bioPageEditDesign: '/bio-page/:id/edit/design',
  referralRedirect: '/referrals/:code',
  promoPartners: '/promo-partners',
  authIframe: '/auth-iframe',
  royalties: '/royalties',
  royaltiesIncome: '/royalties/income',
  royaltiesBreakdown: '/royalties/breakdown',
  royaltiesBalance: '/royalties/balance',
};

const routesConfig: RouteProps[] = [
  {
    pageKey: 'home',
    value: paths.home,
    page: HomePage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'releases',
    value: paths.releases,
    page: ReleaseListWithSearch,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'tracks',
    value: paths.tracks,
    page: TracksPage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'tracks',
    value: paths.trackTab,
    page: Track,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'bio-page',
    value: paths.bioPages,
    page: BioPage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'bio-page-analyics',
    value: paths.bioPageAnalytics,
    page: BioPageAnalytics,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'bio-page-edit-content',
    value: paths.bioPageEditTabs,
    page: BioPageEdit,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseEdit,
    page: ReleaseEditBaseData,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseEditArtwork,
    page: ReleaseEditArtworkPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseEditTracklist,
    page: ReleaseEditTracklistPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseEditDistribution,
    page: ReleaseEditDistributionPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseEditReview,
    page: ReleaseEditReviewPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'release',
    value: paths.releaseView,
    page: ReleaseViewReleaseDetails,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'promo-partners',
    value: paths.promoPartners,
    page: PromoPartners,
    isInGlobalNav: true,
    isPublic: false,
    isNewFeature: false,
    isParentRoute: true,
  },
  {
    pageKey: 'stats',
    value: paths.stats,
    page: StatsPage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'balance',
    value: paths.balance,
    page: BalancePage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
    customNavItem: (props) => (
      <Feature
        children={null}
        name="balance-dashboard-v2"
        fallback={<MenuItem {...props} />}
      />
    ),
  },
  {
    pageKey: 'royalties',
    value: paths.royalties,
    page: RoyaltiesPage,
    isInGlobalNav: true,
    isPublic: false,
    isParentRoute: true,
    customNavItem: (props) => (
      <Feature name="balance-dashboard-v2">
        <MenuItem {...props} />
      </Feature>
    ),
  },
  {
    pageKey: 'login',
    value: paths.login,
    page: LoginPage,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'loginRedirect',
    value: paths.loginRedirect,
    page: LoginCiamRedirect,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'register',
    value: paths.register,
    page: RegisterPage,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'email-verification',
    value: paths.emailVerification,
    page: EmailVerificationPage,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'referralRedirect',
    value: paths.referralRedirect,
    page: ReferralRedirect,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'account',
    value: paths.account,
    page: AccountPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: true,
  },
  {
    pageKey: 'profileDetails',
    value: paths.profileDetails,
    page: AccountPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: false,
  },
  {
    pageKey: 'royaltyPayouts',
    value: paths.royaltyPayouts,
    page: AccountPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: false,
  },
  {
    pageKey: 'subscription',
    value: paths.subscription,
    page: AccountPage,
    isInGlobalNav: false,
    isPublic: false,
    isParentRoute: false,
  },
  {
    pageKey: 'referralProgram',
    value: paths.referralProgram,
    page: AccountPage,
    isInGlobalNav: true,
    isPublic: false,
    isNewFeature: true,
    isParentRoute: false,
    customNavItem: (props) => {
      return (
        <SubscriptionConditional
          shouldDisplay={(subscription) =>
            subscription?.plan !== SubscriptionPlan.GEMA_BASIC
          }
        >
          <MenuItem {...props} />
        </SubscriptionConditional>
      );
    },
  },
  {
    pageKey: 'signOutRedirect',
    value: paths.signOutRedirect,
    page: () => <Navigate to={paths.login} />,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
  {
    pageKey: 'iframe',
    value: paths.authIframe,
    page: () => <CrossDomainCredentialsIframe />,
    isInGlobalNav: false,
    isPublic: true,
    isParentRoute: true,
  },
];

export default routesConfig;
